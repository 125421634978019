import { Card, CardContent, Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import {
  Confirm,
  Create,
  DateTimeInput,
  FormDataConsumer,
  Loading,
  RadioButtonGroupInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  maxLength,
  minLength,
  number,
  required,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import { useSaveContext } from 'ra-core';
import { useFormContext } from 'react-hook-form';
import { PriceInput } from '../../components';
import { CPFInput } from '../../components/Inputs';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';
import { PendingTransactionOriginType } from '../../models/pendingTransactionType';
import { FillinOrigin } from '../../models';
import RequiredChain from '../../components/RequiredChain';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { formatNumber, strip } from '../../utils';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  fullWrapper: {
    display: 'flex',
    width: '100%',
  },
  fillinBox: {
    padding: '10px',
    borderWidth: 1
  },
  fillinText: {
    margin: '5px',
  },
  dateTimePicker: {
    display: 'flex',
    marginLeft: 16,
    gap: 16,
  }
});

const AddFillinToolbar = ({ onSuccessCallback }) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const form = useFormContext();
  const saveContext = useSaveContext();

  const verifySubmit = async (value) => {
    if (value.pricePerLiter !== undefined && (value.pricePerLiter < 1 || value.pricePerLiter > 10)) {
      setConfirmDialog(true);
      return;
    }
    form.handleSubmit(handleSubmit)();
  }

  const handleSubmit = async (value) => {
    setLoading(true);
    saveContext.save && await saveContext.save(value, {
      onSuccess: () => {
        onSuccessCallback();
        form.reset();
        window.scrollTo(0, 0);
        notify('ra.notification.created', {
          type: 'info',
          messageArgs: { smart_count: 1 },
        });
        refresh();
        setLoading(false);
      },
      onError: () => {
        form.reset();
        window.scrollTo(0, 0);
        refresh();
        setLoading(false);
      }
    });
  }

  const handleConfirmDialog = () => {
    setConfirmDialog(false);
    form.handleSubmit(handleSubmit)();
  }

  const handleCloseDialog = () => {
    setConfirmDialog(false);
  }

  return (
    <Toolbar>
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <SaveButton
              label="Pontuar"
              type="button"
              disabled={loading}
              onClick={form.handleSubmit(verifySubmit)}
            />
            <Confirm
              isOpen={confirmDialog}
              title="Atenção"
              content={
                <Alert
                  variant="outlined"
                  severity="warning"
                >
                  O preço por litro é <strong>{formatNumber(formData.pricePerLiter, { style: 'currency', currency: 'BRL', minimumFractionDigits: 3, maximumFractionDigits: 3 })}</strong> <br />
                  Este valor parece incorreto. Tem certeza que deseja continuar?
                </Alert>
              }
              onConfirm={handleConfirmDialog}
              onClose={handleCloseDialog}
            />
          </>
        )}
      </FormDataConsumer>
    </Toolbar>
  );
};

const FillinRenderer = () => {
  const classes = useStyles();
  const record = useRecordContext();
  if (!record) { return null; }

  return (
    <Card style={{ margin: 15, padding: 6 }}>
      <p className={classes.fillinText}>
        Valor: R$ {record.total}
      </p>
      <p className={classes.fillinText}>
        Litros: {record.volume}
      </p>
      <p className={classes.fillinText}>
        Combustível: {record.fuel?.name}
      </p>
      <p className={classes.fillinText}>
        Data: {moment(record.dateTime).format('DD/MM/YYYY HH:mm:ss')}
      </p>
    </Card>
  );
};

const AddPointsByRefuel = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const [hoseNumber, setHoseNumber] = useState();
  const [hoseChoices, setHoseChoices] = useState();
  const [stationId, setStationId] = useState(getPlaceId());
  const [customerCpf, setCustomerCpf] = useState();
  const [fillins, setFillins] = useState();
  const [loading, setLoading] = useState();
  const [selectedFillin, setSelectedFillin] = useState();
  const { data: chain } = useGetOne('chains', { id: getChainId() });
  const [customerInfo, setCustomerInfo] = useState();
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, 'hour').toISOString());
  const [dateTo, setDateTo] = useState(dayjs().toISOString());

  useEffect(() => {
    if (stationId && hoseNumber) {
      setFillins([]);
      setLoading('Estamos buscando os abastecimentos desse bico');
      dataProvider.getList('last-fillins', { filter: { stationId, hoseNumber, dateFrom, dateTo } })
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            setFillins(data)
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [customerCpf, hoseNumber, dateFrom, dateTo]);

  useEffect(() => {
    if (stationId) {
      setLoading('Estamos buscando os bicos do posto');
      dataProvider.getList(`stations/${stationId}/hose-numbers`, {})
        .then(({ data }) => {
          setLoading(false);
          setHoseChoices(data);
        })
        .catch(error => {
          setLoading(false);
          setHoseChoices();
          notify("Nenhum bico encontrado neste posto.")
        });
    } else {
      setHoseChoices();
    }
  }, [stationId]);

  const transform =
    useCallback(
      data => {
        if (fillins && fillins.length && selectedFillin) {
          data.fillin = fillins.find(f => f.deliveryId === +selectedFillin);
        }
        const { dateFrom: from, dateTo: to, ...rest } = data;
        return rest;
      }, [selectedFillin, fillins]);

  const onSuccess = () => {
    setStationId(null);
    setHoseNumber(null);
    setHoseChoices();
    setFillins(null);
    setStationId(getPlaceId() || null);
    setCustomerInfo(null);
  };

  const fetchCustomerApi = useCallback(() => {
    dataProvider.getOne('chains/chainId/customers', { id: strip(customerCpf) })
      .then(({ data }) => {
        setCustomerInfo(data);
      })
      .catch(() => {
        notify('Não foi possível verificar o CPF informado', { type: 'warning' });
      });
  }, [customerCpf]);

  useEffect(() => {
    setCustomerInfo(null);

    if (isValidCPF(customerCpf)) {
      fetchCustomerApi();
    }
  }, [customerCpf]);

  const CustomerCard = () => (
    <Card variant="outlined" sx={{ marginBottom: '10px' }}>
      <CardContent sx={{ padding: '20px!important' }}>
        <Typography variant="caption" dangerouslySetInnerHTML={{ __html: `O CPF informado corresponde ao cliente <strong>${customerInfo.name || '[sem nome]'}</strong>` }} />
      </CardContent>
    </Card>
  );

  return (
    <Permission permission={PERMISSIONS.ADD_POINTS_BY_FUEL}>
      <Create title="Adicionar Pontos" redirect={false} resource={`chains/chainId/pending-transaction`} transform={transform}>
        <SimpleForm loading={loading} defaultValues={{ stationId: getPlaceId(), origin: PendingTransactionOriginType.linkedFillin, originFillin: FillinOrigin.manuallyAdded }} toolbar={<AddFillinToolbar onSuccessCallback={onSuccess} />}>
          <div className={classes.fullWrapper}>
            <CPFInput
              source="cpf"
              label="CPF"
              isRequired
              onChange={(event) => setCustomerCpf(event.target.value)}
            />
            {!getPlaceId() &&
              (<ReferenceInput
                source="stationId"
                filter={{ chainId: getChainId() }}
                reference="stations"
                perPage={null}
                sort={{ field: 'name', order: 'ASC' }}
                validate={required("Posto é obrigatório")}
              >
                <SelectInput
                  label="Posto"
                  sx={{ marginLeft: 2 }}
                  emptyText="Todos"
                  onChange={(event) => setStationId(event.target.value)}
                  optionText="name" />
              </ReferenceInput>)
            }
            {
              Permission({
                permission: PERMISSIONS.ADMIN,
                children: (
                  <div className={classes.dateTimePicker}>
                    <DateTimeInput
                      source="dateFrom"
                      label="Data de Início"
                      defaultValue={dayjs().subtract(1, 'hour').toISOString()}
                      onChange={from => setDateFrom(dayjs(from.target.value))}
                    />
                    <DateTimeInput
                      source="dateTo"
                      label="Data Final"
                      defaultValue={dayjs().toISOString()}
                      onChange={to => setDateTo(dayjs(to.target.value))}
                    />
                  </div>
                )
              })
            }
            {
              (chain && chain.useAttendantCode) &&
              <TextInput
                source="attendantCode"
                label="Código do frentista"
                inputProps={{
                  maxLength: 4
                }}
                validate={[required(), minLength(4), maxLength(4), number('Somente números')]}
                sx={{ marginLeft: 2 }}
              />
            }
            {
              Permission({
                permission: PERMISSIONS.ADMIN,
                children: (
                  <PriceInput
                    source="pricePerLiter"
                    precision="3"
                    validate={required()}
                    label="Preço por litro"
                    sx={{ marginLeft: 2 }}
                  />
                )
              })
            }
          </div>
          {
            customerInfo &&
            <CustomerCard />
          }
          <div>
            {stationId && hoseChoices &&
              <RadioButtonGroupInput
                source="hose"
                optionText="hoseNumber"
                label="Bicos"
                choices={hoseChoices}
                validate={required("Bico é obrigatório")}
                onChange={(event) => setHoseNumber(event.target.value)}
              />
            }
          </div>
          {!loading && fillins && fillins.length === 0 &&
            <Typography fullWidth variant="body1" component="h4">
              Não encontramos abastecimentos recentes pra esse bico.
            </Typography>
          }
          {loading && <Loading loadingSecondary={loading} />}
          {fillins && fillins.length > 0 &&
            <RadioButtonGroupInput
              className={classes.fillinBox}
              fullWidth
              label="Escolha um Abastecimento"
              source="deliveryId"
              choices={fillins}
              validate={required("Escolha um Abastecimento")}
              optionText={<FillinRenderer record={{}} />}
              // optionText={optionRenderer}
              onChange={(event) => setSelectedFillin(event.target.value)} />
          }
        </SimpleForm>
      </Create>
    </Permission>
  );
};

export default (props) => (
  <RequiredChain>
    <AddPointsByRefuel {...props} />
  </RequiredChain>
);